frappe.rc_details = {
    rc_verification_html:function(rc_data){
        html = `<div class="rc-tab">
                <button class="tablinks" onclick="frappe.rc_details.openDetails(event, 'registration_details')">Registration Details</button>
                <button class="tablinks" onclick="frappe.rc_details.openDetails(event, 'vehicle_details')">Vehicle Details</button>
                <button class="tablinks" onclick="frappe.rc_details.openDetails(event, 'owner_details')">Owner Details</button>
                <button class="tablinks" onclick="frappe.rc_details.openDetails(event, 'insurance_details')">Insurance Details</button>
                <button class="tablinks" onclick="frappe.rc_details.openDetails(event, 'permit_details')">Permit Details</button>
                <button class="tablinks" onclick="frappe.rc_details.openDetails(event, 'pucc_details')">PUCC Details</button>
            </div>
            
            <div id="registration_details" class="tabcontent">
            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Vehicle Number
                    </div>
                    <div class="content-val">
                        ${rc_data["data"]["registration"]["number"]}
                    </div>
                </div>
            
                <div class="content-object">
                    <div class="content-key">
                        Status
                    </div>
                    <div class="content-val">
                        ${rc_data["data"]["registration"]["status"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Date
                    </div>
                    <div class="content-val">
                        ${rc_data["data"]["registration"]["date"]}
                    </div>
                </div>
            </div>

            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Expiry Date
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["registration"]["expiry_date"]}
                    </div>
                </div>
            
                <div class="content-object">
                    <div class="content-key">
                        Authority
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["registration"]["authority"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        NOC Details
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["registration"]["noc_details"]}  
                    </div>
                </div>
            </div>

            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Blacklist Status
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["registration"]["blacklist_status"]}
                    </div>
                </div>
            
                <div class="content-object">
                    <div class="content-key">
                        Tax Upto
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["registration"]["tax_upto"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Financer
                    </div>
                    <div class="content-val">
                        ${rc_data["data"]["financer"]}
                    </div>
                </div>
            </div>
        </div>
        
        <div id="vehicle_details" class="tabcontent">
            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Vehicle Number
                    </div>
                    <div class="content-val">
                        ${rc_data["data"]["vehicle"]["number"]}
                    </div>
                </div>
            
                <div class="content-object">
                    <div class="content-key">
                        Fuel Type
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["fuel_type"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Body Type
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["body_type"]}
                    </div>
                </div>
            </div>

            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Norms Description
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["norms_description"]}
                    </div>
                </div>
            
                <div class="content-object">
                    <div class="content-key">
                        Number of Cylinders
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["num_cylinders"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Manufacturing Date
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["manufacturing_date"]}
                    </div>
                </div>
            </div>

            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Company Name
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["company_name"]}
                    </div>
                </div>
            
                <div class="content-object">
                    <div class="content-key">
                        Model Name
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["model_name"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Class
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["class"]}
                    </div>
                </div>
            </div>

            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Category
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["category"]}
                    </div>
                </div>
            
                <div class="content-object">
                    <div class="content-key">
                        Color
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["color"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Chassis No
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["chassis_no"]}
                    </div>
                </div>
            </div>

            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Engin Number
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["engin_number"]}
                    </div>
                </div>
            
                <div class="content-object">
                    <div class="content-key">
                        Wheel Base
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["wheel_base"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Cubic Capacity
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["cubic_capacity"]}
                    </div>
                </div>
            </div>

            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Seat Capacity
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["seat_capacity"]}
                    </div>
                </div>
            
                <div class="content-object">
                    <div class="content-key">
                        Unladen Weight
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["unladen_weight"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Gross Weight
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["vehicle"]["gross_weight"]}
                    </div>
                </div>
            </div>
        </div>
        
        <div id="owner_details" class="tabcontent">
            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Name
                    </div>
                    <div class="content-val">
                        ${rc_data["data"]["owner"]["name"]}
                    </div>
                </div>
            
                <div class="content-object">
                    <div class="content-key">
                        Number
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["owner"]["number"]}
                    </div>
                </div>
            </div>

            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Fathers Name
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["owner"]["fathers_name"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Mobile Number
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["owner"]["mobile_number"]}
                    </div>
                </div>
            </div>

            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Present Address
                    </div>
                    <div class="content-val" style="margin-right: 20%;">
                    ${rc_data["data"]["owner"]["district"]}, ${rc_data["data"]["owner"]["present_address"]}, ${rc_data["data"]["owner"]["state"]}, ${rc_data["data"]["owner"]["pincode"]}
                    </div>
                </div>
            </div>

            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Permanent Address
                    </div>
                    <div class="content-val" style="margin-right: 20%;">
                    ${rc_data["data"]["owner"]["district"]}, ${rc_data["data"]["owner"]["permanent_address"]}, ${rc_data["data"]["owner"]["state"]}, ${rc_data["data"]["owner"]["pincode"]}
                    </div>
                </div>
            </div>
        </div>
        
        <div id="insurance_details" class="tabcontent">
            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Policy Number
                    </div>
                    <div class="content-val">
                        ${rc_data["data"]["insurance"]["policy_number"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Company
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["insurance"]["company"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Valid Upto
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["insurance"]["valid_upto"]}
                    </div>
                </div>
            </div>
        </div>
        
        <div id="permit_details" class="tabcontent">
            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Permit Number
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["permit"]["permit_number"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Type
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["permit"]["ype"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Code
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["permit"]["code"]}
                    </div>
                </div>
            </div>

            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        Issue Date
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["permit"]["issue_date"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Valid From
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["permit"]["valid_from"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Valid Upto
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["permit"]["valid_upto"]}
                    </div>
                </div>
            </div>
        </div>
        
        <div id="pucc_details" class="tabcontent">
            <div class="content-row">
                <div class="content-object">
                    <div class="content-key">
                        PUCC Number
                    </div>
                    <div class="content-val">
                        ${rc_data["data"]["pucc"]["number"]}
                    </div>
                </div>

                <div class="content-object">
                    <div class="content-key">
                        Valid Upto
                    </div>
                    <div class="content-val">
                    ${rc_data["data"]["pucc"]["valid_upto"]}
                    </div>
                </div>
            </div>
        </div>`

        return html;
    },
    openDetails:function(evt, tabDetails){
            var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        document.getElementById(tabDetails).style.display = "block";
        evt.currentTarget.className += " active";
    }
}