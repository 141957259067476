frappe.timeline_step = {
  setup_page: function (page, show) {
    var page_header = $(
      "div[id='page-" + page + "'] > .page-body > .page-toolbar",
    );
    if (show) {
      page_header.removeClass("hide");
    } else {
      page_header.addClass("hide");
    }

    page_header = $(
      "div[id='page-" + page + "'] > .page-body > .page-toolbar > .container",
    );
    page_header.css("width", "100%");
    page_header = page_header.empty();
    return page_header;
  },

  setup: function (page, frm, final_step) {
    var show = true;
    if (!frm.doc.timeline || frm.doc.timeline.length == 0) {
      show = false;
    }
    var page_header = this.setup_page(page, show);
    if (frm.doc.timeline && frm.doc.timeline.length > 0) {
      var steps = ``;
      for (var i = 0; i < frm.doc.timeline.length; i++) {
        var timeline = frm.doc.timeline[i];
        var circle = "done";
        if (timeline.status == "Rejected") {
          circle = "rejected";
        }
        // for last step change class
        var mark_as = "done";
        if (i == frm.doc.timeline.length - 1) {
          mark_as = "sub";
        }

        var timeline_html =
          `<div class="text_circle ` +
          mark_as +
          `">
				<div class="circle">
				<h4>` +
          timeline.status +
          `</h4>
				<small>` +
          moment(timeline.time_recorded).format("DD-MMM HH:mm") +
          `</small>
				</div>
				<a href="javascript:void(0)" class="tvar"><div class="` +
          circle +
          `"></div></a>
				</div>`;
        steps += timeline_html;
      }
      if (frm.doc.payout_status != final_step) {
        steps +=
          `<div class="text_circle">
				<div class="circle">
				<h4>` +
          final_step +
          `</h4>
				<small></small>
				</div>
				<a href="javascript:void(0)" class="tvar"><div class="pending"></div></a>
				</div>`;
      }
      page_header.append(
        '<div class="line_box stav_projektu">' + steps + "</div>",
      );
    }
  },

  future_steps: function (page, timelines, current_step) {
    console.log("future_steps", timelines, current_step);
    var show = true;
    if (!timelines || timelines.length == 0) {
      show = false;
    }
    var page_header = this.setup_page(page, show);
    if (timelines) {
      var steps = ``;
      for (var i = 0; i < timelines.length; i++) {
        var timeline = timelines[i];
        var circle = "done";
        if (timeline.status == "Rejected") {
          circle = "rejected";
        }
        if (i > current_step) {
          circle = "pending";
        }
        // for last step change class
        var mark_as = "done";
        if (i == current_step) {
          mark_as = "sub";
        }
        if (i > current_step) {
          mark_as = "";
        }
        var time_stamp = "";
        if (timeline.time_recorded) {
          time_stamp = moment(timeline.time_recorded).format("DD-MMM HH:mm");
        }
        var timeline_html =
          `<div class="text_circle ` +
          mark_as +
          `">
                <div class="circle">
                <h4>` +
          timeline.status +
          `</h4>
                <small>` +
          time_stamp +
          `</small>
                </div>
                <a href="javascript:void(0)" class="tvar"><div class="` +
          circle +
          `"></div></a>
                </div>`;
        steps += timeline_html;
      }

      page_header.append(
        '<div class="line_box stav_projektu">' + steps + "</div>",
      );
    }
  },
};
