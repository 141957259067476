let _current_overlays = [];

$(document).ready(function () {
  $("body").addClass("full-width");

  // fix for print format
  $(document).on("DOMNodeInserted", function (e) {
    if ($(e.target).hasClass("preview-beta-wrapper")) {
      $(".preview-beta-wrapper >iframe").css("height", "1144.01px");
    }
  });
});

window.autocompleteobj = false;
frappe.googlemap_plotter = {
  handl_geojson: function (frm, map, data) {
    // show legend for tracking page, for rest, will console catched error
    try {
      const my_div = document.getElementById("my-div");
      var legend_div = document.createElement("div");
      legend_div.setAttribute("id", "legend");
      legend_div.innerHTML = "<h3>Legend</h3>";
      my_div.append(legend_div);

      const legend = document.getElementById("legend");
      const legendContentDiv = document.createElement("div");
      legendContentDiv.setAttribute("id", "legend-content");
      legendContentDiv.innerHTML =
        "<div class='origin-div'>" +
        "<img src='/assets/execution/images/origin_map_marker.png'>" +
        "<span>Origin</span>" +
        "</div>" +
        "<div class='destination-div'>" +
        "<img src='/assets/execution/images/dest_map_marker.png'>" +
        "<span>Destination</span>" +
        "</div>" +
        "<div class='timestamp-div'>" +
        "<img class='point' src='/assets/execution/images/pin.png'>" +
        "<span>Time Stamps</span>" +
        "</div>";

      legend.appendChild(legendContentDiv);

      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(legend);
    } catch (e) {
      console.log(e);
    }

    if ("features" in data) {
      var markersArray = [];
      data.features.forEach(function (feature) {
        var geometry = feature.geometry;

        if (geometry.type == "LineString") {
          var coordinates = geometry.coordinates;
          var path = [];
          coordinates.forEach(function (coordinate) {
            path.push({ lat: coordinate[1], lng: coordinate[0] });
          });
          var strokeColor = "#0484de";
          var icon = {};
          var arrow = {};
          if ("properties" in feature) {
            if ("stroke" in feature.properties) {
              strokeColor = feature.properties.stroke;
            }
            if ("show_arrow" in feature.properties) {
              arrow = {
                path: "M 0,0 1.1,3.3 -1.1,3.3 0,0 z", // 0,0 is the tip of the arrow
                fillColor: "white",
                fillOpacity: 1.0,
                strokeColor: "#0484de",
                strokeWeight: 1,
              };
              icon = [
                {
                  icon: arrow,
                  offset: "100%",
                  repeat: "80px",
                },
              ];
            }
          }
          var line = new google.maps.Polyline({
            path: path,
            geodesic: true,
            strokeColor: strokeColor,
            strokeOpacity: 0.8,
            strokeWeight: 3,
            showDir: true,
            icons: icon,
          });
          line.setMap(map);

          // to flush marker later
          _current_overlays.push(line);
        }
        if (geometry.type == "Point") {
          var coordinates = geometry.coordinates;
          if (feature.properties.custom_icon) {
            var icon = {
              url: createCustomStopMarkerIcon(feature.properties.hour),
            };
          } else if (feature.properties.icon) {
            var icon = {
              url: feature.properties.icon,
              anchor: new google.maps.Point(
                feature.properties.anchor[0],
                feature.properties.anchor[1],
              ),
            };
          } else {
            var icon = null;
          }

          var marker = new google.maps.Marker({
            position: { lat: coordinates[1], lng: coordinates[0] },
            map: map,
            icon: icon,
          });
          if (feature.properties.custom_icon) {
            marker.zIndex = 1;
          }

          if (feature.properties.html) {
            var infowindow = new google.maps.InfoWindow({
              content: feature.properties.html,
            });

            marker.addListener("click", function () {
              infowindow.open(map, marker);
              //google.maps.event.clearListeners(marker, "mouseout");
            });

            marker.addListener("mouseover", function () {
              infowindow.open(map, marker);
            });
            marker.addListener("mouseout", function () {
              infowindow.close();
            });

            google.maps.event.addListener(infowindow, 'domready', function () {
              $('div.gm-style-iw-chr').hide()
              $('.gm-style-iw').find('div.gm-style-iw-d').css({
                "margin": "10px 10px 10px 0px",
                'overflow': "hidden",
              });
            })
          }
          markersArray.push(marker);

          // to flush marker later
          _current_overlays.push(marker);
        }
        if (geometry.type == "Polygon") {
          var og_infowindow = map.data.addGeoJson(feature);
        }

        if (geometry.type == "MultiPoint") {
          var coordinates = geometry.coordinates;
          var image_blue = {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 5,
            fillOpacity: 1,
            strokeWeight: 2,
            fillColor: "#2CA8FF",
            strokeColor: "#ffffff",
          };
          coordinates.forEach(function (coordinate) {
            var marker = new google.maps.Marker({
              position: { lat: coordinate[1], lng: coordinate[0] },
              map: map,
              icon: image_blue,
            });
            var html = coordinate[2];
            if (html != null) {
              var infowindow = new google.maps.InfoWindow({
                content: html,
              });

              google.maps.event.addListener(infowindow, 'domready', function () {
                $('div.gm-style-iw-chr').hide()
                $('.gm-style-iw').find('div.gm-style-iw-d').css({
                  "margin": "10px 10px 10px 0px",
                  'overflow': "hidden",
                });
              })
            }

            marker.addListener("click", function () {
              infowindow.open(map, marker);
            });

            marker.addListener("mouseover", function () {
              infowindow.open(map, marker);
            });
            marker.addListener("mouseout", function () {
              infowindow.close();
            });
            markersArray.push(marker);

            // to flush overlay later
            _current_overlays.push(marker);
          });
        }
      });

      var markersArrayDu = [];

      markersArrayDu = markersArray.slice();
      if (markersArrayDu.length != 0) {
        var latlngbounds = new google.maps.LatLngBounds();

        for (var i = 0; i <= markersArrayDu.length; i++) {
          latlngbounds.extend(markersArrayDu.pop().getPosition());
        }
        map.fitBounds(latlngbounds);
      }
    }
  },

  place_autocomplete: function (frm, id_, callback = null) {
    const origin = document.querySelector(id_);

    // wait for minimum 5 characters to be entered

    origin.addEventListener("input", function () {
      if (origin.value.length < 5) {
        window.autocompleteobj = false;
        return;
      }

      if (window.autocompleteobj == false) {
        window.autocompleteobj = true;

        let origin_autocomplete = new google.maps.places.Autocomplete(origin);
        google.maps.event.addListener(
          origin_autocomplete,
          "place_changed",
          function () {
            let place = origin_autocomplete.getPlace();
            var latitude = place.geometry.location.lat();
            var longitude = place.geometry.location.lng();
            if (callback) {
              callback(latitude, longitude, place.formatted_address);
            }
          },
        );
      }
    });
  },

  clean_map: function (frm, map) {
    _current_overlays.forEach(function (overlay) {
      overlay.setMap(null);
      google.maps.event.clearInstanceListeners(overlay);
    });

    map.data.forEach(function (feature) {
      map.data.remove(feature);
    });

    _current_overlays = [];
  },
};

function createCustomStopMarkerIcon(number) {
  const svg = `
          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="40">
            <style>
              .marker {
                border-radius: 15px;
                padding: 4px;
                position: absolute;
            
                background: white;
                border: 3px solid #ed6e6e;
                color: #ed6e6e;
                text-align: center;
                text-decoration: bold;
                word-wrap: break-word;
                font-size: 10px;
                font-weight: bold;
              }
            </style>
            <foreignObject x="5" y="5" width="40" height="30">
              <div xmlns="http://www.w3.org/1999/xhtml" class="marker">${number}</div>
            </foreignObject>
          </svg>
        `;
  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
}

var modal_counter = 0;
$(document).on("DOMNodeInserted", function (e) {
  if ($(e.target).hasClass("modal")) {
    $('input[data-fieldname="location"]')
      .not(".modal-counter")
      .addClass("modal-counter-" + modal_counter.toString())
      .addClass("modal-counter");

    $('div[data-fieldname="lat"]').hide();
    $('div[data-fieldname="lng"]').hide();
    try {
      frappe.googlemap_plotter.place_autocomplete(
        {},
        "input.modal-counter-" + modal_counter.toString(),
        function (latitude, longitude, place) {
          $('input[data-fieldname="location"]').val(place);
          $('input[data-fieldname="lat"]').val(latitude);
          $('input[data-fieldname="lng"]').val(longitude);
        },
      );
      modal_counter++;
    } catch (err) {
      console.log(err);
    }
  }

  if ($(e.target).hasClass("pac-item")) {
    $(e.target).parent().css("z-index", 3000);
  }
});
