frappe.list_header = {
  add_page_header_card: function (page_header, title, value, color) {
    page_header.append(
      `
            <div class="ce-block col-lg-3">
                <div class="ce-block__content">
                    <div shortcut_name="Total Trips">
                    <div class="widget shortcut-widget-box" >
                        <div class="widget-head">
                            <div class="widget-label">
                                <div class="widget-title">
                                    <span class="ellipsis" title="` +
        title +
        `">
                                        ` +
        title +
        `
                                    </span>
                                
                                </div>
                                <div class="widget-subtitle"></div>
                            </div>
                            <div class="widget-control">
                                <div class="indicator-pill ellipsis ` +
        color +
        `">` +
        value +
        `</div>
                                </div>
                            </div>
                            <div class="widget-body"></div>
                            <div class="widget-footer"></div>
                        </div>
                    </div>
                </div>
            </div>
            `,
    );
  },
  setup_header: function (page, cards) {
    var page_header = $(
      "div[id*='" + page + "\\/List'] > .page-body > .page-toolbar",
    );
    page_header.removeClass("hide");
    page_header = $(
      "div[id*='" +
        page +
        "\\/List'] > .page-body > .page-toolbar > .container",
    );
    page_header
      .addClass("row")
      .css("padding-bottom", "20px")
      .css("width", "100%");
    page_header = page_header.empty();

    for (var i = 0; i < cards.length; i++) {
      this.add_page_header_card(
        page_header,
        cards[i].title,
        cards[i].value,
        cards[i].color,
      );
      if (cards[i].filter) {
        $("span[title='" + cards[i].title + "']").click(
          { page: page, filter: cards[i].filter },
          this.handle_card_click,
        );
      }
    }
  },
  // handle_card_click: function(e){

  //     frappe.set_re_route("Report",e.data.page, e.data.filter);

  // }
  handle_card_click: function (e) {
    var route = e.data.filter;
    let query_string = frappe.utils.get_query_params();
    if (query_string) {
      route = Object.assign(query_string, route);
    }

    console.log(route);
    // route ="?" +String(Object.entries(route).map(([key, val]) => `${key}=%5B"like"%2C"%25${val}%25"%5D`).join('&'));
    route =
      "?" +
      String(
        Object.entries(route)
          .map(([key, val]) => `${key}=${val}`)
          .join("&"),
      );
    return new Promise((resolve) => {
      frappe.router.push_state(route);
      setTimeout(() => {
        frappe.after_ajax &&
          frappe.after_ajax(() => {
            resolve();
          });
      }, 100);
    }).finally(() => (frappe.route_flags = {}));
  },
};
